const otto = window.otto ?? {};
const tracking = otto.tracking;
const submitEvent = tracking.submitEvent;
const submitEventMerge = tracking.submitEventMerge;
const submitMerge = tracking.submitMerge;
const submitMove = tracking.submitMove;
const submitMiniAction = tracking.submitMiniAction;
const createEventMergeContext = tracking.createEventMergeContext;
const trackOnNextPageImpression = tracking.trackOnNextPageImpression;
const createContext = tracking.createContext;
const replaceContext = tracking.replaceContext;
const closeContext = tracking.closeContext;
const getPageMergeId = tracking.getPageMergeId;
const createPage = tracking.createPage;
function get() {
  return tracking;
}
export {
  closeContext,
  createContext,
  createEventMergeContext,
  createPage,
  get,
  getPageMergeId,
  replaceContext,
  submitEvent,
  submitEventMerge,
  submitMerge,
  submitMiniAction,
  submitMove,
  trackOnNextPageImpression,
  tracking
};
/*                                     */
