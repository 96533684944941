const win = window;
{
  win.AS || (win.AS = {});
  win.o_global || (win.o_global = {});
  win.o_util || (win.o_util = {});
}
win.__toggles__ || (win.__toggles__ = {});
const { o_global, o_util } = win;
export {
  o_global as a,
  o_util as o,
  win as w
};
/*                                                          */
