import { w as win$1 } from "../chunks/common-namespaces-client-DXKc2YAH.mjs";
function get$1(name) {
  const parts = document.cookie.split(/;\s*/);
  const n = `${name}=`;
  const cookieValue = parts.find((part) => {
    return part.startsWith(n);
  });
  if (cookieValue) {
    return decodeURIComponent(cookieValue.replace(n, ""));
  }
  return void 0;
}
const win = window;
{
  win.AS || (win.AS = {});
  win.o_global || (win.o_global = {});
  win.o_util || (win.o_util = {});
}
win.__toggles__ || (win.__toggles__ = {});
const { o_global, o_util, __toggles__ } = win;
function assignNamespace(parent, key, implementation, legacy) {
  parent[key] = Object.assign(parent[key] ?? {}, implementation, legacy);
  return parent[key];
}
function jsonParseSafe(value) {
  try {
    return typeof value === "string" ? JSON.parse(value) : void 0;
  } catch {
    return void 0;
  }
}
const COOKIE_PREFIX = "toggle_";
const cookieMap = { true: 1, false: 0, "1": 1, "0": 0 };
function normalize(toggle, defaultValue, defaultPayload) {
  if (toggle === void 0) {
    return [!!defaultValue, false, defaultPayload];
  }
  if (Array.isArray(toggle)) {
    return [!!toggle[0], !!toggle[1], jsonParseSafe(toggle[2]) ?? defaultPayload];
  }
  return [!!toggle, false, defaultPayload];
}
function normalizeLegacy(toggle, defaultValue, defaultPayload) {
  if (toggle === void 0) {
    return [!!defaultValue, false, defaultPayload];
  }
  if (!!toggle === false) {
    return [false, true, defaultPayload];
  }
  return [true, false, defaultPayload];
}
function getToggle(toggleName, defaultValue = true, defaultPayload = void 0) {
  var _a;
  const rawToggle = __toggles__[toggleName];
  const rawLegacy = (_a = o_global.toggles) == null ? void 0 : _a[toggleName];
  const toggle = rawToggle ? normalize(rawToggle, defaultValue, defaultPayload) : normalizeLegacy(rawLegacy, defaultValue, defaultPayload);
  if (!toggle[1]) {
    return [toggle[0], toggle[2]];
  }
  const localStatus = get$1(COOKIE_PREFIX + toggleName);
  if (!localStatus || cookieMap[localStatus] === void 0) {
    return [toggle[0], toggle[2]];
  }
  return [!!cookieMap[localStatus], toggle[2]];
}
function get(toggleName, defaultValue = false) {
  const [res] = getToggle(toggleName, defaultValue, void 0);
  return res;
}
assignNamespace(o_util, "toggle", { get, getToggle });
function defineFunction(handler) {
  return handler;
}
function defineEvent(...listeners) {
  return { [Symbol.onexus.event]: listeners };
}
const createEventTopic = defineEvent;
function defineNamespace(implementation) {
  return { ...implementation, [Symbol.onexus.namespace]: true };
}
const otto = win$1.otto ?? {};
export {
  createEventTopic,
  defineEvent,
  defineFunction,
  defineNamespace,
  otto
};
/*                                     */
